const messages = {
  sr: {
    orders: {
      actions: {
        addNewOrder: 'Dodati porudžbinu',
        all: 'Sve porudžbine',
      },
      listPage: {
        title: 'Porudžbine',
        table: {
          filter: { number: 'Broj' },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Datum porudžbine',
            number: '',
            offer: 'Ponuda',
            opportunity: 'Prilika',
            owner: 'Vlasnik',
            state: 'Status',
            total: 'Ukupno',
            totalUserCurrency: 'Ukupno u {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nova porudžbina',
        contact: { label: 'Kontakt' },
        commissionTotal: { label: 'Ukupna provizija' },
        currency: { label: 'Valuta' },
        dateOfOrder: { label: 'Datum porudžbine' },
        number: {
          label: '',
          helperText: 'Ostavite prazno za automatsko generisanje broja.',
        },
        offer: { label: 'Ponuda' },
        opportunity: { label: 'Prilika' },
        owner: { label: 'Vlasnik' },
        salesMargin: { label: 'Trgovinska marža' },
        subjects: {
          title: 'Predmet',
          discount: { label: 'Popust' },
          commission: { label: 'Provizija' },
          list: { package: 'Veličina pakovanja' },
          listPrice: { label: 'Cena u skladu sa cenovnikom' },
          price: { label: 'Prodajna cena' },
          product: { label: 'Proizvod' },
          quantity: { label: 'Količina' },
          salesMargin: { label: 'Trgovinska marža' },
          subjectTotal: { label: 'Za proizvod ukupno' },
          unit: { label: 'Jedinica' },
        },
        total: { label: 'Ukupno' },
        numberOfSubjects:
          '{number, plural, =0 {nema stavki} one {1 stavka} few {{number} stavki} other {{number} stavki}}',
      },
      myOrdersScoringBlog: { title: 'Moje porudžbine' },
      ordersList: 'Porudžbine',
    },
  },
};

export default messages;
