const messages = {
  sr: {
    app: {
      about: 'O aplikaciji ',
      actions: {
        add: 'Dodati',
        addNamed: 'Dodati {name}',
        remove: 'Izbrisati',
        removeNamed: 'Izbrisati {name}',
        removeConfirmation: 'Jeste li sigurni da želite izbrisati?',
        more: 'Više',
        less: 'Manje',
        download: 'Preuzeti',
        save: 'Spremiti',
        cancel: 'Otkazati',
        close: 'Zatvoriti',
        moreFormFields: 'Još više polja',
        export: 'Izvoziti',
        edit: 'Urediti',
        createdSuccessMessage: 'Uspešno kreirano.',
        savedSuccessMessage: 'Uspešno izmenjeno.',
        deletedSuccessMessage: 'Uspešno izbrisano',
        detail: 'Detalji',
        next: 'Sledeće',
        back: 'Nazad',
        start: 'Početak',
        whatNext: 'Šta je sledeće...',
        confirm: 'Potvrda',
        confirmRemove: 'Jeste li sigurni da želite izbrisati?',
        search: 'Traži',
        userProfile: 'Korisnički profil',
        tenantProfile: 'Profil vašeg CRM-a',
        imports: 'Uvozi',
        fileImports: 'Uvoz datoteke',
        settings: 'Postavke',
        logout: 'Odjaviti se',
        login: 'Prijaviti se',
        filterList: 'Lista filtera',
        excel: 'Excel',
        refresh: 'Obnoviti',
        clear: 'Očistiti',
        agree: 'Slažem se',
        disagree: 'Ne slažem se',
        makeCopy: 'Napraviti kopiju',
        fullscreen: 'Ceo ekran',
        fullscreenClose: 'Zatvorite režim celog ekrana',
        copy: 'Kopirati',
      },
      messages: {
        confirmRemove: 'Jeste li sigurni da želite izbrisati ovu stavku?',
        errorOccurred: 'Dogodila se greška',
        createdSuccess: 'Kreiranje je uspelo.',
        savedSuccess: 'Čuvanje je uspelo.',
        deletedSuccess: 'Brisanje je uspelo.',
        uploadSuccess: 'Dokument je uspešno prebačen.',
        uploadError: 'Greška pri prenosu datoteke.',
        uploadInProgress: 'Traje prenos...',
        uploadedFiles:
          '{number, plural, one {1 dokument je uspešno prenesen} few {{number} dokumenata je uspešno preneseno} other {{number} dokumenta je uspešno preneseno}}',
      },
      error: 'Greška!',
      validations: {
        isRequired: 'Polje {field} je obavezno.',
        minLengthIsRequired:
          'Potrebno je najmanje {number, plural, one {1 stavka} few {{number} stavki} other {{number} stavki}}.',
        minValue: 'Najveća dopuštena vrednost je {value}.',
        maxValue: 'Najveća dopuštena vrednost je {value}.',
        passwordMatchError: 'Lozinke nisu identične',
        invalidEmail: 'Nevažeći email.',
        invalidPhoneNumber:
          'Nevažeći telefonski broj. Unesite telefonski broj u međunarodnom formatu + X XXX XXX XXX "."',
        notValidNumber: 'Nevažeći broj',
        invalidUrl: 'Nevažeća URL adresa.',
        positive: 'Broj mora biti pozitivan.',
        negative: 'Broj mora biti negativan.',
      },
      login: {
        title: 'Prijavite se na Sprinx CRM',
        username: 'Korisničko ime',
        password: 'Lozinka',
        loginButton: 'Prijaviti se',
        error: 'Prijava nije uspela. Korisničko ime ili lozinka nisu pronađeni',
        pleaseRelogin: 'Čini se da niste prijavljeni. Molimo prijavite se ponovo.',
      },
      days: '{count, plural, one {1 dan} few {{formattedCount} dana} other {{formattedCount} dana}}',
      overdue: 'kašnjenje {count, plural, one {1 dan} few {{formattedCount} dana} other {{formattedCount} dana}}',
      newItem: 'Novi',
      anonymizing: {
        confirm: {
          title: 'Potvrdite anonimizaciju',
          content: 'Jeste li sigurni da želite anonimizirati lične podatke?',
        },
        button: 'Anonimizirajte lične podatke',
      },
      appMenu: { discover: 'Otkrijte' },
      missingType: 'Prvo definišite {typeName} u administraciji aplikacije.',
      missingTypeForEntityType: 'Prvo definišite {typeName} za {forEntityTypeName} u administraciji aplikacije.',
      emptyScoringBlog: 'Moduli čiji se sadržaj ili podešavanja prikazuju na ovoj stranici nisu aktivirani.',
    },
    components: {
      buttons: { showOnMap: 'Prikaži na mapi' },
      filters: {
        filterButtonSubmit: 'Traži',
        filterFieldName: '',
        addCondition: 'Dodati uslov ',
        resetFilter: 'Resetovati filter',
        operators: {
          contains: { 0: 'sadrži', _: 'sadrži' },
          containsexact: { 0: 'sadrži tačno', _: 'sadrži tačno' },
          notcontains: { 0: 'ne sadrži', _: 'ne sadrži' },
          betweennumber: { 0: 'od', 1: 'do', _: 'između' },
          betweendate: { 0: 'od', 1: 'do', _: 'između' },
          equal: { 0: 'jednako je', _: 'jednako je' },
        },
      },
      paginationList: {
        itemsTotal: 'Ukupno stavki: {number}',
        nextPage: 'Učitati {number} stavki',
        emptyMessage: 'Nije pronađeno',
      },
      selects: { emptyOption: 'Nijedna', emptyMessage: 'Nije pronađeno' },
      tables: {
        labelRowsPerPage: 'Redova na stranici',
        sortBy: 'Poređati po {field}',
        recordsChanged: 'Neki su zapisi dodati ili promenjeni.',
      },
      textField: { errorMsgWrapper: 'Greška: {msg}' },
      dateField: {
        invalidDateFormat: 'Pogrešan format datuma.',
        invalidDate: 'Nepoznato',
        maxDateMessage: 'Datum ne može biti veći od maksimalnog datuma',
        minDateMessage: 'Datum ne može biti manji od minimalnog datuma',
        today: 'Danas',
      },
      detailNavigation: {
        scoringblog: 'Scoring Blog',
        card: 'Karta',
        relations: 'Sesija',
      },
      manipulationInfo: {
        created: 'Kreirano',
        updated: 'Promenjeno',
        removed: 'Izbrisano',
      },
    },
  },
};

export default messages;
