// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
// @ts-ignore
import CldrLocaleSr from '@sprinx/react-globalize/CldrLocaleSr';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
import React from 'react';
import MessagesSr from './i18n/MessagesSr';

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}
const defaultLocale = 'sr';
const supportedLocales = ['sr'];

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  return (
    <GlobalizeProvider
      cldrs={{ sr: CldrLocaleSr }}
      messages={{ sr: MessagesSr }}
      defaultLocale={defaultLocale}
      supportedLocales={supportedLocales}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
