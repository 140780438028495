import PropTypes from 'prop-types';
import comOrdersMessages from './react-com-orders/sr';
import comMessages from './react-com/sr';
import formsMessages from './react-forms/sr';
import compMessages from './react-mui-components/sr';
import tablesMessages from './react-mui-tables/sr';
import shoppingCartMessages from './react-shopping-cart/sr';
import messages from './sr';

function MessagesSr({ children }) {
  return children([
    compMessages,
    tablesMessages,
    formsMessages,
    comMessages,
    shoppingCartMessages,
    comOrdersMessages,
    messages,
  ]);
}

MessagesSr.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesSr;
