import InvoicesProvider from '@sprinx/cvo-eshop-ui-common/@sprinx/react-invoices/InvoicesProvider';
import WarrantyClaimsProvider from '@sprinx/cvo-eshop-ui-common/@sprinx/react-warranty-claims/WarrantyClaimsProvider';
// HOC for static build
import applicationSettings from '@sprinx/cvo-eshop-ui-common/app/applicationSettings';
import CartSummaryPrice from '@sprinx/cvo-eshop-ui-common/app/CartSummaryPrice';
import AddToCartButtonToQty from '@sprinx/cvo-eshop-ui-common/components/AddToCardButtonToQty';
import AddToCart from '@sprinx/cvo-eshop-ui-common/components/AddToCart';
import ScrollToTop from '@sprinx/cvo-eshop-ui-common/components/ScrollToTop';
import UserFavoritesProviderImp from '@sprinx/cvo-eshop-ui-common/favorites/UserFavoritesProvider';
import LastVisitedProductsProvider from '@sprinx/cvo-eshop-ui-common/products/LastVisitedProductsProvider';
import ProductComparisonProvider from '@sprinx/cvo-eshop-ui-common/products/ProductComparisonProvider';
import useProductImageAdornmentsMapper from '@sprinx/cvo-eshop-ui-common/products/useProductImageAdornmentsMapper';
import useProductsServiceCallListTransformed from '@sprinx/cvo-eshop-ui-common/products/useProductsServiceCallListTransformed';
import useShoppingCurrency from '@sprinx/cvo-eshop-ui-common/shop/useShoppingCurrency';
import useShoppingNoTaxPrices from '@sprinx/cvo-eshop-ui-common/shop/useShoppingNoTaxPrices';
// import AppProductParametersInline from '@sprinx/cvo-eshop-ui-common/components/AppProductListItemRow/AppProductParametersInline';
import withChildrenIfStaticBuild from '@sprinx/cvo-eshop-ui-common/withChildrenIfStaticBuild';
// import useShoppingCartContent from '@sprinx/react-shopping-cart/useShoppingCartContent';
import OrdersProviderImp from '@sprinx/react-com-orders/OrdersProvider';
import ComProvider from '@sprinx/react-com/ComProvider';
import ProductDetailLink from '@sprinx/react-com/ProductDetailLink';
import ProductImage from '@sprinx/react-com/ProductImage';
import ProductParameterValue from '@sprinx/react-com/ProductParameterValue';
import useSnackbar from '@sprinx/react-mui-layout/useSnackbar';
import PricingProvider from '@sprinx/react-pricing/PricingProvider';
// import ShoppingListsProviderImp from '@sprinx/react-shopping-lists/ShoppingListsProvider';
import ProductsProviderImp from '@sprinx/react-products/ProductsProvider';
import useProductsServiceCallFindOneByIdOrSkuCached from '@sprinx/react-products/useProductsServiceCallFindOneByIdOrSkuCached';
import ShoppingCartProvider from '@sprinx/react-shopping-cart/ShoppingCartProvider';
import PropTypes from 'prop-types';
import React from 'react';
import pricingRulesData from './pricingRulesData';

const { pricingRules, pricingRulesCompiled } = pricingRulesData;
// const pricingRules = [];
// const pricingRulesCompiled = [];

const Dummy = () => null;

// adjust for static
const ProductsProvider = withChildrenIfStaticBuild(ProductsProviderImp);
const UserFavoritesProvider = withChildrenIfStaticBuild(UserFavoritesProviderImp);
// const ShoppingListsProvider = withChildrenIfStaticBuild(ShoppingListsProviderImp);
const OrdersProvider = withChildrenIfStaticBuild(OrdersProviderImp);

const isStaticBuild = typeof window === 'undefined';

function useProductFindDummy() {
  return () => Promise.resolve(null);
}
const useProductFind = isStaticBuild ? useProductFindDummy : useProductsServiceCallFindOneByIdOrSkuCached;

const codeListOptions = { disableOnAuth: true };

function CommerceBoilerplate({ children }) {
  const currency = useShoppingCurrency();
  const noTaxPrices = useShoppingNoTaxPrices();
  const [showMessage] = useSnackbar();
  return (
    <ProductsProvider codeListOptions={codeListOptions}>
      <ComProvider
        AddToCartComponent={AddToCart}
        imageAdornmentsHook={useProductImageAdornmentsMapper}
        ProductParameterValueComponent={ProductParameterValue}
      >
        <PricingProvider
          currency={currency}
          noTaxPrices={noTaxPrices}
          productFinderHook={useProductFind}
          staticBuild={true}
          initialRules={pricingRules}
          initialRulesCompiled={pricingRulesCompiled}
          decimalPrecision={2}
        >
          <ShoppingCartProvider
            showSnackbarMessage={showMessage}
            AddToCartComponent={AddToCartButtonToQty}
            ProductImageComponent={ProductImage}
            ProductDetailLinkComponent={ProductDetailLink}
            ProductPriceComponent={CartSummaryPrice}
            productsServiceCallListHook={useProductsServiceCallListTransformed}
            preventDecrementRemoveSubject={applicationSettings.preventDecrementRemoveSubject}
          >
            {/* <ShoppingListsProvider
              shoppingCartContentHook={useShoppingCartContent}
              productsServiceCallListHook={useProductsServiceCallListTransformed}
            > */}
            <OrdersProvider
              ContactSelectFieldPreviewNaked={Dummy}
              ContactSelectFieldPreview={Dummy}
              // ProductParametersComponent={AppProductParametersInline}
            >
              <ProductComparisonProvider>
                <UserFavoritesProvider>
                  <LastVisitedProductsProvider keepCount={4}>
                    <InvoicesProvider>
                      <WarrantyClaimsProvider>
                        <ScrollToTop>{children}</ScrollToTop>
                      </WarrantyClaimsProvider>
                    </InvoicesProvider>
                  </LastVisitedProductsProvider>
                </UserFavoritesProvider>
              </ProductComparisonProvider>
            </OrdersProvider>
            {/* </ShoppingListsProvider> */}
          </ShoppingCartProvider>
        </PricingProvider>
      </ComProvider>
    </ProductsProvider>
  );
}

CommerceBoilerplate.propTypes = {
  children: PropTypes.node,
};

CommerceBoilerplate.defaultProps = {
  children: null,
};

export default CommerceBoilerplate;
