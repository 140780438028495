const messages = {
  sr: {
    shoppingCart: {
      actions: {
        addToCart: 'Dodati u korpu',
        buy: 'U korpu',
        order: 'Naručiti',
        remove: 'Izbrisati',
      },
      cart: {
        total: { label: 'Ukupno' },
        totalItems: { label: 'Ukupno po redovima' },
        totalShipping: { label: 'Ukupno za prevoz' },
        totalPayment: { label: 'Ukupno za uplatu' },
        totalTaxes: { label: 'Ukupni porezi' },
        resetCart: 'Resetuj korpu',
      },
      messages: {
        confirmResetCart: 'Jeste li sigurni da želite izbrisati sadržaj korpe?',
        giftClaim: 'Na poklon možete dobiti {quantity} komada {productName} proizvoda.',
        cartManipulations: {
          allRemoved: 'VAŠA KORPA JE PRAZNA! Trenutno namate ni jedan proizvod u Vašoj korpi.',
          itemAdded: '{number, plural, one {Proizvod je dodat u korpu} other {Proizvodi su dodati u korpu}}.',
          itemRemoved: '{number, plural, one {Proizvod je uklonjen iz korpe} other {Proizvodi su uklonjeni iz korpe}}.',
          quantityChaged: 'Promenila se kolicina proizvoda.',
          quantityChanged: 'Promenila se kolicina proizvoda.',
        },
      },
      components: {
        appBartActionCart: 'Korpa',
        quantityField: {
          states: {
            quantityRounded: 'Količina je zaokružena na najbližu veličinu pakovanja.',
          },
        },
        cartAsidePreview: { cartEmpty: 'Vaša korpa je prazna' },
        cartAfterAddModal: {
          continueShopping: 'Nastaviti sa kupovinom',
          goToCart: 'Preći u korpu',
          title: 'Proizvodi dodati u korpu',
          pcs: 'kom',
        },
      },
    },
  },
};

export default messages;
