const messages = {
  sr: {
    reactMuiTables: {
      selected: 'odabrano',
      filterhide: 'Sakrij filter',
      filtershow: 'Prikaži filter',
      refresh: 'Obnoviti',
      filter: {
        actions: {
          search: 'Tražiti',
          resetFilter: 'Resetovati filter',
          addCondition: 'Dodati uslov',
        },
      },
      standardOperators: {
        equals: 'ravno',
        contains: 'sadrži',
        between: { label: 'između', from: 'od', to: 'do' },
        oneOf: 'jedan od',
      },
      labelRowsPerPage: 'Redovi po stranici',
    },
  },
};

export default messages;
