export default {
  pricingRules: [
    {
      superGroup: 0,
      ruleThread: 'sale',
      ruleCode: 'sale',
      ruleName: 'Sale',
      ruleType: 'subject',
      targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
      map: ['subjectSale', ['SKU-SALE']],
      valueAdded: 'subjectPriceDifference',
    },
    {
      superGroup: -1,
      ruleThread: 'multi-gift-hr',
      ruleCode: 'multi-gift-hr',
      ruleName: 'Darek',
      ruleType: 'subject',
      noFootprint: true,
      targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
      map: ['subjectSingleProductMultipleGifts', ['product.pricingExtensions.gifts.RS']],
      valueAdded: 'subjectPriceDifference',
    },
    {
      superGroup: 1,
      ruleThread: 'free-shipping-product',
      ruleCode: 'free-shipping-product',
      ruleName: 'Doprava zdarma',
      ruleType: 'cart',
      targets: [{ constraints: [['cartTaxonomyQuantitySum', ['/free-shipping', 'gte', 1]]] }],
      map: ['cartShippingFixPrice', [0, 'SHIPPING-FREE']],
    },
  ],
  pricingRulesCompiled: [
    [
      -1,
      [
        {
          superGroup: -1,
          ruleThread: 'multi-gift-hr',
          ruleCode: 'multi-gift-hr',
          ruleName: 'Darek',
          ruleType: 'subject',
          noFootprint: true,
          map: ['subjectSingleProductMultipleGifts', ['product.pricingExtensions.gifts.RS']],
          valueAdded: 'subjectPriceDifference',
          targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
        },
      ],
    ],
    [
      0,
      [
        {
          superGroup: 0,
          ruleThread: 'sale',
          ruleCode: 'sale',
          ruleName: 'Sale',
          ruleType: 'subject',
          map: ['subjectSale', ['SKU-SALE']],
          valueAdded: 'subjectPriceDifference',
          targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
        },
      ],
    ],
    [
      1,
      [
        {
          superGroup: 1,
          ruleThread: 'free-shipping-product',
          ruleCode: 'free-shipping-product',
          ruleName: 'Doprava zdarma',
          ruleType: 'cart',
          map: ['cartShippingFixPrice', [0, 'SHIPPING-FREE']],
          targets: [{ constraints: [['cartTaxonomyQuantitySum', ['/free-shipping', 'gte', 1]]] }],
        },
      ],
    ],
  ],
};
