const messages = {
  sr: {
    com: {
      components: { headerCartButton: { cartButton: 'Korpa' } },
      productDetail: { parameters: { title: 'Parametri' } },
      productAvailability: {
        format: {
          available: 'Na zalihama',
          moreThanX: 'Više od {min} komada na zalihama',
          exactCount: '{value} komada na zalihama',
          notAvailable: 'Nije dostupno',
        },
      },
      imageGallery: { thumbnailsMore: '+ {number} više' },
      relatedProducts: { title: 'Srodni proizvodi' },
      productAccessories: { title: 'Oprema i dodaci' },
      productDetailImageGallery: { noImage: 'Bez slike' },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kada ću dobiti robu?',
      },
    },
  },
};

export default messages;
