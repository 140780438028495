const messagesSr = {
  sr: {
    parameters: {
      yes: 'DA',
    },
    com: {
      phase: { label: 'Označite' },
      product: {
        ean: { label: 'EAN', helperText: '', helperTextPreview: '' },
        sku: { label: 'Šifra proizvoda', helperText: '', helperTextPreview: '' },
        manufacturer: { label: 'Proizvođač', helperText: '', helperTextPreview: '' },
        pn: { label: 'Broj naloga', helperText: '', helperTextPreview: '' },
        warranty: { label: 'ROK ZA REKLAMACIJU', months: 'MESECA' },
      },
      totalAmount: { label: 'Zajedno', helperText: '' },
    },
    imageGallery: { thumbnailsMore: '+ {number} više' },
    orders: {
      actions: { all: 'Sve porudžbine' },
      myOrdersScoringBlog: { title: 'Moje porudžbine' },
      ordersList: 'Porudžbine ',
      phase: {
        1: 'Prihvaćen',
        '01-new': 'Prihvaćen',
        10: 'Razmatra se',
        20: 'Čeka se plaćanje',
        30: 'Plaćeno',
        98: 'Poređati',
        99: 'Otkazano',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Broj paketa',
            phase: 'Stanje',
            paid: 'Plaćeno',
            total: 'Bez PDV-a',
            totalWithTax: 'Ukupno',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Korpa',
        buyButton: 'Pošaljite porudžbinu',
        emptyBasket: 'Vaša korpa je prazna',
        buyThere: 'Već sam kupovao/la ovde',
        login: 'Želim da se prijavim',
        notFill: 'Detalji isporuke nisu popunjeni',
        fill: 'Popunite podatke',
        change: 'Izmena podataka',
        popupButton: 'Prikaži na mapi',
        payment: { title: 'PLAĆANJE', free: 'Besplatno', changePaymentBtn: 'PROMENITI PLAĆANJE' },
        shipping: {
          title: 'DOSTAVA',
          free: 'Besplatno',
          changeShippingBtn: 'PROMENITI DOSTAVE',
          popup: { title: 'Informacije', text: 'Dostava na kućnu adresu direktno sa kurirskom službom {service}' },
        },
      },
      actions: { all: 'Sve korpe' },
      myBasketsScoringBlog: { title: 'Sačuvane liste za kupovinu' },
      cartSummaryParameters: { caption: 'Šifra proizvoda' },
    },
    productDetailSpagettyNavigation: {
      description: 'Opis',
      parameters: 'Parametri',
      package: 'Sadržaj paketa',
      fotoAndVideo: 'Slika i video',
      alternatives: 'Slično',
      alternativesText: 'Na žalost trenutno nemamo zamenu za proizvod, koji ste odabrali.',
      questions: 'Pitanja',
      productDescriptionNotAvailable: 'Opis proizvoda nije dostupan',
    },
    newsletter: {
      phone: '+381 62 8132264',
      subscribe: {
        title: 'Ne propustite posebne ponude',
        inputText: 'Vaša e-mail adresa',
        button: 'Prijavite se',
      },
    },
    globalNavigation: {
      home: 'Početak',
      shopping: 'O trenutku kupovine',
      orders: 'Porudžbine ',
      shoppingLists: 'Spisak za kupovinu',
      articles: 'Proizvodi',
    },
    homePageWhy: {
      0: {
        title: 'Kupujte sigurno',
        content: 'Punopravni smo član asocijacije E-commerce I garantujemo bezbednu online kupovinu.',
      },
      1: { title: 'Korisnička podrška', content: 'Zadovoljstvo kupaca nam je bitno, stoga smo uvek tu za vas.' },
      2: {
        title: 'Pick up point',
        content:
          'Poručenu robu možete preuzeti u našem objektu preko puta Vukovog spomenika, na adresi Golsvordijeva 36.',
      },
      3: {
        title: 'Raspoloživost robe',
        content: 'Na lageru imamo 98% celokupnog asortimana, odmah spremnog za isporuku.',
      },
      title: 'ZAŠTO KUPOVATI OD NAS?',
    },
    startPage: { title: 'Naslovna strana', gotoShopping: 'Nastavi kupovinu' },
    shoppingCart: {
      buyBtn: 'DODAJ U KORPU',
      alternatives: 'Slično',
      shipping: { changePoint: 'Izmenite' },
      agreements: { termsRequired: 'Morate se složiti sa uslovima poslovanja.' },
      validation: { someProductNotAvailable: 'Neki proizvodi nisu na zalihama u dovoljnim količinama.' },
    },
    appCartSummaryProductParameters: { title: 'Šifra proizvoda:' },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Preporučeno',
        name: 'Ime',
        priceAsc: 'Od najviše cene',
        priceDesc: 'Od najniže cene',
      },
    },
    orderStatuses: { storno: 'Otkazati', synchronized: 'Poslato', waiting: 'Čaka se sinhronizacija' },
    orderPreview: { items: 'Proizvodi   ' },
    cartRegisterForm: { title: 'Obrazac za registraciju' },
    cartPayment: { title: 'Plaćanje  ' },
    cartPreSummaryPage: { title: 'Sadržaj korpe', actions: { nextStep: 'Slažem se i nastavljam' } },
    cartCompanyForm: { description: 'Popunite, ako kupujete robu za kompaniju.' },
    cartDeliveryForm: {
      description: 'Popunite, ako se adresa za isporuku razlikuje od adrese za naplatu.',
    },
    productAvailabilityFieldPreviewWithPopup: { button: 'Kada ću dobiti poručenu robu?' },
    productDetailStockInfo: {
      available: 'Proizvod imamo u našem distributivnom skladištu.',
      external: 'Proizvod je na putu do našeg skladišta',
      notAvailable: 'Roba je privremeno nedostupna',
      labels: { free: 'BESPLATNA DOSTAVA', showroom: 'IZLOŽENO U MALOPRODAJI' },
    },
    productGifts: { btnName: 'POKLON' },
    homepage: {
      productOfMonth: 'Proizvodi meseca',
      catalogFavorite: 'Najpopularnije kategorije',
      hotTips: 'Ekskluzivna ponuda',
      recommended: 'Preporučujemo',
    },
    productQuestion: {
      firstName: 'Ime',
      lastName: 'Prezime',
      email: 'E-mail',
      subject: 'Naslov ',
      question: 'Pitanje',
      gdpr: 'Postavljanjem pitanja pristajem na obradu ličnih podataka kako bih dobio odgovor na pitanje.',
      gdprLink: 'GDPR veza',
      button: 'Pošaljite upit',
      subjectContent: 'Pitanje o proizvodu: {product}, kod: {sku}',
      title: 'Morate se složiti sa obradom ličnih podataka.',
      success: 'Upit je poslat',
    },
    lastVisitedProducts: 'Nedavno posećeni proizvodi',
    productAlternativeProducts: 'Slično',
    productAccessoryProducts: 'Dodaci',
    productReplacementProducts: 'Dodatak',
    productAccessories: 'Dodatak',
    productReplacements: 'Zamena',
    appProductDetailTabs: {
      description: 'Opis',
      parameters: 'Parametri',
      package: 'Sadržaj paketa',
      query: 'Pitanje',
      media: 'Slika i video',
      alternatives: 'Slično',
      accessories: 'Dodaci',
      replacements: 'Dodatak',
      form: { enum: '!!!! ENUM VREDNOST' },
    },
    cartSummaryPage: {
      title: 'Pregled porudžbina',
      actions: { continueShopping: 'Nastavi kupovinu', saveOrder: 'Sastavi porudžbinu' },
      expectedDeliveryDate: { label: 'Obavezan datum isporuke', helperText: '', helperTextPreview: '' },
      noteForSeller: { label: 'Napomena za kurira', helperText: '', helperTextPreview: '' },
      paymentGateway: { label: 'Način plaćanja', helperText: '', helperTextPreview: '' },
    },
    userProfile: {
      title: 'Moj nalog',
      content: 'Sadržaj',
      contactInfo: {
        newsletter: {
          title: 'Slanje obaveštenja putem e-maila',
          content: 'Želim da dobijam obaveštenja sa sajta Digiexpert.rs',
        },
        title: 'Kontakt podaci',
      },
      orders: { title: 'Istorija porudžbina' },
      invoices: { title: 'Moje fakture' },
      myOrders: 'Moje porudžbine',
      myInvoices: 'Moje fakture',
      logout: 'Odjaviti se',
      login: 'Prijaviti se',
      favorites: { title: 'Omiljeno' },
      changePassword: {
        title: 'Promeni lozinku',
        fields: {
          login: 'Login (ne može se promeniti)',
          originalPassword: 'Originalna lozinka',
          newPassword: 'Nova lozinka',
          confirmNewPassword: 'Potvrdi lozinku',
        },
        button: 'Sačuvaj promene',
      },
    },
    orderSavedThankPage: {
      title: 'Zahvaljujemo se na porudžbini {orderNumber} !',
      thankMessage: {
        template:
          'Na vašu e-mail adresu ćemo poslati sve neophodne podatke o porudžbini. Ukoliko imate dodatnih pitanja možete nas kontaktirati na broj telefona {phoneNumber} ili na email {email}',
        phoneNumber: '+381 62 8132264',
        email: 'info@digiexpert.rs',
      },
      order: 'Redni broj:',
      payment: 'Način plaćanja:',
      cencelled: 'Otkazano',
      accepted: 'Prihvaćeno',
      pending: 'U toku',
      paid: 'Plaćeno',
      actions: {
        back: 'Povratak na glavnu stranicu',
        detail: 'Detalji',
        continue: 'Nastavi kupovinu',
      },
      decline: 'Odbijeno',
    },
    shoppingLists: {
      title: 'Spisak za kupovinu',
      actions: { saveAsShoppingList: 'Sačuvaj listu za kupovinu', importShoppingListToCart: 'Uvoz' },
    },
    searchResultPage: { search: 'Termin za pretragu', results: 'Rezultati pretrage' },
    homePageArticles: { title: 'Novosti', button: 'Prikaži sve artikle' },
    loginPage: {
      title: 'Prijava',
      forgotPassword: 'Resetovanje lozinke',
      email: 'E-mail',
      sendEmail: 'Pošalji e-mail',
      info: 'Dobićete e-mail sa linkom za promenu lozinke',
      password: 'Lozinka',
      notSame: 'Ne podudara se',
      newPassword: 'Nova lozinka',
      confirmPassword: 'Potvrdi lozinku',
      changePassword: 'Promeni lozinku',
    },
    productPrice: {
      withoutTax: 'bez PDV-a',
    },
    productDetailPage: {
      availability: {
        dateRange: [
          '{minDays, select,',
          '      2 {Poručite {today} i već za {minDays}-{maxDays} dana će Vam biti poslato.}',
          '      3 {Poručite {today} i već za {minDays}-{maxDays} dana će Vam biti poslato.}',
          '  other {Poručite {today} i već za {minDays}-{maxDays} dana će Vam biti poslato.}',
          '}',
        ],
        nextDateTime: '11:30',
        today: 'Sve porudžbine realizovane do {time}, biće poslate isti dan.',
        todayWord: 'danas',
        tomorrow: 'Poručite još {today}, porudžbina će Vam biti poslata već sutra ujutru.',
        dayOfWeek: {
          message: 'Poručite još {today}, porudžbina će Vam biti poslata već u {day}.',
          1: 'ponedeljak',
          2: 'utorak',
          3: 'sreda',
          4: 'četvrtak',
          5: 'petak',
          6: 'subota',
          0: 'nedelja',
        },
      },
      allDescription: 'KOMPLETAN OPIS PROIZVODA',
      demoProduct: { text: 'KUPITE JEFTINIJE za:' },
      productNote: {
        title: 'KUPITI JEFTINIJE za: 2.150,00€',
        note: 'Beleška 2 – Dodatni opis stanja ili slične informacije o proizvodu, zašto je snižen i šta se nalazi na robi …',
      },
      adornments: {
        notForSale: 'Ne prodaje se odvojeno',
        usedProduct: 'Bazar',
        freeGift: 'Besplatan poklon',
        freeShipping: 'Besplatna dostava',
        eol: 'Zaključena prodaja',
        demo: 'Prezentacijski proizvod',
        sale: 'Popust',
      },
      disponibility: {
        primaryLessThan: 'NA LAGERU manje od {count} komada',
        primaryMoreThan: 'NA LAGERU {count, plural, zero {eden kos} other {{count} i više komada}}',
        secondaryLessThan: 'NA LAGERU u pripremnom skladištu manje {br} od {count} komada',
        secondaryMoreThan:
          'NA LAGERU u pripremnom skladištu {br} {count, plural, zero {eden kos} other {{count} i više komada}}',
        external: 'Proizvod je na putu do našeg skladišta',
        unavailable: 'Proizvod nije dostupan',
        showroom: '(izložen u Maloprodaji)',
        soldout: 'Proizvod je rasprodat',
        'on-way': {
          d: '',
          m: 'Na putu za {from, plural, one {minut} two {minuta} other {minuta}}',
          M: 'Na putu za {from, plural, one {mesec} two {meseca} few {meseci} other {meseci}}',
          dRange: 'Na putu za {to, plural, few {{from}-{to} dana} other {{from}-{to} dana}}',
          mRange: 'Na putu za {from}-{to, plural, one {dan} few {dana} other {dana}}',
          MRange: 'Na putu {from}-{to, plural, one {mesec} two {meseca} few {meseci} other {meseci}}',
          date: '{datum}',
        },
        time: 'Na putu za {count, plural, zero {jedan komad} other {{count} i nekoliko komada}}',
      },
    },
    comparePage: {
      title: 'Uporedite',
      removeButton: 'Uklonite proizvod',
      nothing: 'Nema proizvoda za upoređivanje',
    },
    filter: { reset: 'Resetujte filter', search: 'Pretraga' },
    orderTermsAndConditions: { select: { label: 'Uslovi porudžbine', helperText: '', helperTextPreview: '' } },
    orderDetailPage: { actions: { import: 'Dodaj u korpu' } },
    invoiceBlogPreview: { title: 'Moje fakture', button: 'Sve fakture' },
    userProfilePage: { logout: 'Odjava', setting: 'Podešavanja', passwordChange: 'Promeni lozinku' },
    appBarActionRegion: { changeRegion: 'Promeni jezik', label: 'Završi' },
    subCategories: { title: 'Podkategorije' },
    aboutShoppingPage: {
      title: 'O KUPOVINI',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONSKO BELEŽENJE PRIHODA',
      informationDuty: 'OBAVEZNA INFORMACIJA',
      processingOfPersonalData: 'OBRADA LIČNIH PODATAKA',
      cashback: 'CASHBACK',
      delivery: 'DOSTAVA',
      availability: 'DOSTUPNOST',
      methodOfPayment: 'NAČIN PLAĆANJA',
      termsAndConditions: 'USLOVI',
      termsOfAppeal: 'USLOVI ŽALBE',
      contractwWithdrawal: 'POVLAČENJE UGOVORA',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Poreski broj je verifikovan, cene se naplaćuju bez PDV-a.',
        viesNotVerified: 'Vaš poreski broj nije verifikovan ili se ne kvalifikujete za kupovinu bez PDV-a. ',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'INFORMACIJE O DOSTAVI',
      pleaseComplete: 'UNETI PODATKE',
      closeButton: 'Završi',
    },
    cookiesBar: {
      agreeBtn: 'Slažem se',
      title: '',
      text: 'Sajt digiexpert.rs koristi kolačiće radi što boljeg iskustva naših posetilaca. Daljim korišćenjem sajta smatramo da ste saglasni sa našom politikom privatnosti i potvrđujete prihvatanje kolačića.',
    },
    cartFormLogin: {
      btnSend: 'Pošalji',
      button: 'Prijavi se',
      title: 'Prijava',
      forgottenPasswordBtn: 'Zaboravili ste lozinku?',
      text1: 'Na navedenu e-mail adresu poslaćemo jednokratnu lozinku.',
      text2: 'Nakon prijavljivanja, postavite novu lozinku.',
      error: 'Neispravno korisničko ime ili lozinka.',
    },
    cartSummartSection: { title: 'REZIME UGOVORA' },
    appCartSummarySubjectsList: { title: 'Naslov', text: 'Ime/opis' },
    newsletterText: {
      text1: 'Potreban vam je savet?',
      text2: 'Možete nas pozvati svakog radnog dana od 8 do 16 sati.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(bez PDV)',
      priceLabel: 'CENA/TROŠKOVI',
      amount: 'Količina',
      totalPriceLabel: 'UKUPNA CENA',
      totalPrice: 'Ukupno',
      submitButton: 'Pošaljite porudžbinu',
    },
    cartSummarySubjectsListItemDirectPreview: { amountTitle: 'kom' },
    cartSummaryListItem: { freeTitle: '- Besplatno', amountTitle: 'kom' },
    sxCartNotes: { note: 'Ne navodi datum isporuke', addNote: 'Dodajte napomenu' },
    productDetail: {
      productSendLink: { copyLinkBtn: 'Kopiraj link', sendLinkByEmail: 'Pošalji link e-mailom' },
      productDetailBarInformation: {
        addToFavourites: 'Dodaj u omiljene',
        compareProduct: 'Uporedite proizvod',
        sendLink: 'Pošalji link',
        serviceList: 'Spisak usluga',
        questions: 'Pitanja',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'Kupujem za kompaniju',
        otherDeliveryInfo: 'Adresa za dostavu je drugačija',
        otherDeliveryInfoAlert: 'Obveznik PDV-a ne može koristiti drugu adresu za isporuku.',
      },
      companyNameLabel: 'Kompanija',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Prezime',
      streetLabel: 'Ulica',
      cityLabel: 'Grad',
      zipLabel: 'Poštanski broj',
      companyIDLabel: 'Poreski broj',
      countryLabel: 'Država',
    },
    cartFormPersonal: {
      label: 'Adresa za dostavu',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Prezime',
      streetLabel: 'Ulica, kućni broj',
      pscLabel: 'Poštanski broj',
      cityLabel: 'Grad',
      countryLabel: 'Država',
      phoneLabel: 'Telefonski broj',
      emailLabel: 'e-mail adresa',
      passwordLabel: 'Lozinka',
      controlPasswordLabel: 'Kontrolna lozinka',
    },
    cartFormInvoice: {
      labelDesktop: 'Kontakt podaci / adresa za naplatu',
      labelMobile: 'Kontakt podaci',
      companyLabel: 'Kompanija',
      password: 'Lozinka',
      confirmPassword: 'Verifikacija lozinke',
      email: 'e-mail adresa',
      firstName: 'Ime',
      lastName: 'Prezime',
      countryLabel: 'Država',
      phone: 'Telefonski broj',
      phoneError: 'Telefonski broj je netačan',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Kućni broj',
      zipLabel: 'Poštanski broj',
      icoLabel: 'IR',
      dicLabel: 'Poreski broj',
      country: 'Država',
      cityLabel: 'Grad',
      registration: 'Želim da otvorim nalog',
      registrationNote: 'i može pratiti vaše porudžbine, pošiljke i plaćanja ...',
      button: 'Sačuvaj promene',
    },
    page404: {
      title: 'Žao nam je, stranica ne postoji...',
      subTitle: 'Nastavite do početne stranice.',
      button: 'Povratak na početnu stranicu.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Sortiraj po:',
      byName: 'Ime',
      byPrice: 'Cena',
      onlyStock: 'Samo na lageru',
      resetFilter: 'Otkazati',
      displayCountOfProducts: 'Proizvodi:',
      search: 'Pretraga',
      clear: 'Izbriši',
      ok: 'OK',
    },
    priceRangeSliderField: { title: 'Raspon cena' },
    productListMultipleFilterMobileDialog: { headerTitle: 'Filtriraj po' },
    productListMultipleFilterMobile: { title: 'FILTRIRATI' },
    cartCompany: {
      companyName: 'NAZIV FIRME',
      regNo: 'MATIČNI BROJ FIRME',
      taxNo: 'PIB',
      taxNoPlaceholder: 'RS00000000',
      warningMessage: {
        warning: '',
        message: '',
      },
    },
    searchResultsPage: {
      match: 'Za {searchText} smo našli {resultCountFormatted} rezultate.',
      nomatch: 'Za {searchText} nismo našli nikakve rezultate.',
      matchingCategories: 'Пронађене категорије',
      button: 'Povratak na početnu stranicu.',
    },
    selectMultipleFilter: { totalChipsLabel: 'Izabrano {total} proizvoda' },
    cartDelivery: {
      firstName: 'Ime',
      lastName: 'Prezime',
      companyName: 'Kompanija',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Kućni broj',
      zipLabel: 'Poštanski broj',
      country: 'Država',
      cityLabel: 'Grad',
    },
    cartNotes: {
      shippingLabel: 'Dodajte napomenu dostavljaču',
      shippingNote: 'Napomena za dostavljača',
      sellerLabel: 'Dodajte napomenu prodavcu',
      sellerNote: 'Napomena za prodavca',
      loginNote: 'Ako već imate nalog kod nas, ispunićemo sve za vas nakon registracije.',
    },
    cartCountryShipping: {
      title: 'Zemlja isporuke',
      label: 'Izbor zemlje utiče na način plaćanja, dostave i datum isporuke',
    },
    cartAddress: {
      bllingAddress: 'Adresa za naplatu',
      deliveryAddress: 'Adresa isporuke',
      pickUpPointAddress: 'Adresa mjesta preuzimanja',
    },
    cartAgreements: {
      customerVerified:
        'Ne slažem se sa slanjem upitnika koji je deo programa ‘’Odobreno kupcima’’, koji se sprovodi u cilju merenja zadovoljstva kupaca, radi poboljšanja naših usluga.',
    },
    loginDialog: { username: 'Korisničko ime', password: 'Lozinka' },
    sprinxFormsValidation: {
      confirmPassword: 'Lozinke koje ste uneli nisu iste',
      invalidPostalCode: 'Navedeni poštanski broj nije u važećem formatu',
      usernameIsNotFree: 'Korisnik sa ovim imenom je već registrovan.',
      emailEmpty: 'Neispravan e-mail!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Dodaj novu fakturu',
        all: 'Sve fakture',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Broj',
            invoiceDate: 'Datum fakture',
            dueDate: 'Do datuma',
            total: 'Bez PDV-a',
            totalWithTax: 'Ukupno',
            billingAddress: 'Adresa za naplatu',
            orderNumber: 'Redni broj',
            unpaidAmount: 'Neplaćeni iznos',
          },
          filter: {
            number: 'Broj',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Pregled faktura',
          columns: {
            priceUnit: 'Cena/jedinica',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Ukupna cena porudžbine',
      tax: 'PDV',
      withoutTax: 'Ukupna cena bez PDV-a',
    },
    compare: {
      addToCompare: 'Proizvod je dodat u polje za poređenje',
      removeToCompare: 'Proizvod je uklonjen iz polja za poređenje',
      price: 'Cena',
      ean: 'ean',
    },
    favorite: {
      addToFavorite: 'Proizvod je dodat u omiljene',
      removeFromFavorite: 'Proizvod je uklonjen iz omiljenih',
    },
    registration: {
      important: 'VAŽNO!',
      note: 'Poslaćemo vam jednokratnu lozinku e-mailom. Nakon prijave, unesite novu lozinku.',
      gdprAgreement: 'Morate se složiti sa obradom ličnih podataka',
      submitButton: 'Registruj se',
      fail: 'Registracija nije bila uspešna, pokušajte ponovno kasnije',
      success: 'Registracija je uspešna',
      title: 'Registracija',
      dialog: 'Ako još uvek nemate nalog kod nas, možete se registrovati',
      here: 'ovde',
    },
    appBarActionGlobalSearchComposed: { label: 'Pretražite proizvode' },
    appBarActions: {
      about: 'O kupovini',
      aboutHref: '/o-kupovini',
      compare: 'Uporedite',
    },
    appBarActionUser: { title: 'Prijava' },
    footer: { copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. sva prava zadržana' },
    appArticlesPage: { title: 'Tekstovi' },
    errorPage: { title: 'Nešto nije u redu.', button: 'Početna strana' },
    cartSummary: { withoutTax: 'bez PDV-a' },
    renewPasswordForm: {
      success: 'Nova lozinka je poslata na vašu e-mail adresu.',
      emailNotFound: 'Имејл није пронађен!',
      error: 'Nešto nije u redu',
    },
    cartSubjectListItemQuantityWarning: 'Trenutna količina proizvoda na stanju je {count}',
  },
};

export default messagesSr;
