const messages = {
  sr: {
    sprinxFormsValidation: {
      required: 'Polje je obavezno!',
      string: 'Mora biti tekstualni niz!',
      stringEmpty: 'Ne sme biti prazno!',
      stringMin: 'Dužina mora biti veća od ili jednaka dužini {expected} znakova!',
      stringMax: 'Dužina mora biti manja ili jednaka dužini {expected} znakova!',
      stringLength: 'Dužina mora biti duga {expected} znakova!',
      stringPattern: 'Ne zadovoljava traženi obrazac!',
      stringContains: "Mora sadržati tekst '{expected} '!",
      stringEnum: 'Ne odgovara ni jednoj od dopuštenih vrednosti!',
      stringNumeric: 'Mora biti broj!',
      number: 'Mora biti broj!',
      numberMin: 'Mora biti veće od ili jednako {expected}!',
      numberMax: 'Mora biti manje ili jednako {expected}!',
      numberEqual: 'Mora biti jednako sa {expected}!',
      numberNotEqual: 'Ne može biti jednako sa {expected}!',
      numberInteger: 'Mora biti celi broj!',
      numberPositive: 'Mora biti pozitivan broj!',
      numberNegative: 'Mora biti negativan broj!',
      array: 'Mora biti popis!',
      arrayEmpty: 'Ne sme biti prazan popis!',
      arrayMin: 'Potrebno je najmanje {number, plural, one {1 stavka} few {{number} stavke} other {{number} stavki}}!',
      arrayMax:
        'Ne može sadržati više od {number, plural, one {1 stavke} few {{number} stavke} other {{number} stavki}}!',
      arrayLength:
        'Mora sadržati tačno {expected, plural, one {1 stavku} few {{number} stavke} other {{number} stavki}}!',
      arrayContains: "Mora sadržati '{expected}'!",
      arrayEnum: "Vrednost '{expected}' ne odgovara ni jednoj dopuštenoj vrednosti!",
      boolean: 'Mora biti "da" ili "ne"!',
      function: 'Mora biti funkcija!',
      date: 'Mora biti datum!',
      dateMin: 'Mora biti veće od ili jednako {expected}!',
      dateMax: 'Mora biti manje ili jednako{expected}!',
      forbidden: 'Zabranjeno polje!',
      email: 'Neispravan e-mail!',
      url: 'Nevažeći URL!',
      object: 'Mora biti tipa Object!',
      arrayReducedEquals: 'Sažeta vrednost ne odgovara {expected}!',
      phoneNumber: "Mora biti važeći međunarodni telefonski broj u formatu '+ XXX XXX XXX XXX '!",
      dateGt: 'Datum mora biti veći od {expected}!',
      dateGte: 'Datum mora biti veći od ili jednak {expected}!',
      dateLt: 'Datum mora biti manji od {expected}!',
      dateLte: 'Datum mora biti manji od ili jednak {expected}!',
    },
  },
};

export default messages;
